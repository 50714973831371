import axios from "axios"
import { Link } from "gatsby"
import React, { useState } from "react"
import {
  BrandInstagram,
  BrandFacebook,
  BrandLinkedin,
  DeviceMobile,
  Check,
  AlertCircle,
  Checks,
  Mail,
  BrandTiktok,
} from "tabler-icons-react"
import { colors } from "../assets/icons"
import Blob from "./Blob"

const socialLinks = [
  {
    icon: <BrandInstagram size={20} strokeWidth={1} color={colors.text} />,
    text: "@mmv_advogados",
    url: "https://www.instagram.com/mmv_advogados",
  },
  {
    icon: <BrandTiktok size={20} strokeWidth={1} color={colors.text} />,
    text: "@mmv.advogados",
    url: "https://www.tiktok.com/@mmv.advogados",
  },
  {
    icon: <BrandFacebook size={20} strokeWidth={1} color={colors.text} />,
    text: "MMV Advogados",
    url: "https://www.facebook.com/mmvadvogadoss",
  },
  {
    icon: <BrandLinkedin size={20} strokeWidth={1} color={colors.text} />,
    text: "MMV Advogados",
    url: "https://linkedin.com/company/mmv-advogados",
  },
  {
    icon: <DeviceMobile size={20} strokeWidth={1} color={colors.text} />,
    text: "915 485 823",
    url: "tel:915485823",
  },
  {
    icon: <Mail size={20} strokeWidth={1} color={colors.text} />,
    text: "geral@mmv-advogados.com",
    url: "mailto:geral@mmv-advogados.com",
  },
]

const usefulLinks = [
  {
    text: "Termos e Condições",
    url: "/termos-condicoes",
  },
  {
    text: "Política de Privacidade",
    url: "/politica-privacidade",
  },
  {
    text: "Política de Cookies",
    url: "/politica-cookies",
  },
]

const Footer = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(null)
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    assunto: "",
    mensagem: "",
    aceita_politica: false,
  })
  const apiURL =
    "https://backoffice.mmv-advogados.com/wp-json/contact-form-7/v1/contact-forms/14/feedback"

  const handleChange = (o, isCheckbox) => {
    setShowMessage(false)
    setError(false)
    setFormData({
      ...formData,
      [isCheckbox ? "aceita_politica" : o.target.id]: isCheckbox
        ? !formData.aceita_politica
        : o.target.value,
      //o.target.type === "checkbox" ? o.target.checked :  o.target.value,
    })
  }
  const handleSubmit = () => {
    console.log(formData)
    if (
      formData.nome !== "" &&
      formData.email !== "" &&
      formData.assunto !== "" &&
      formData.mensagem !== "" &&
      formData.aceita_politica
    ) {
      const params = new FormData()
      for (const field in formData) {
        params.append(field, formData[field])
      }
      axios
        .post(apiURL, params)
        .then(response => {
          console.log(response)
          if (response?.data?.status === "mail_sent") {
            setShowMessage(true)
            setMessage("Mensagem enviada com sucesso.")
          } else {
            setError(true)
            setShowMessage(true)
            setMessage("Ocorreu um erro ao enviar, tente novamente mais tarde.")
          }
        })
        .catch(e => {
          console.log(e)
          setError(true)
          setShowMessage(true)
          setMessage("Ocorreu um erro ao enviar, tente novamente mais tarde.")
        })
    } else {
      setError(true)
      setShowMessage(true)
      setMessage("Deve preencher todos os campos")
    }
  }

  const isContact = window.location.pathname.includes("contactos")
  return (
    <footer id="agendar-consulta">
      {!isContact && (
        <div className="contact-section">
          <div className="container">
            <ul className="social-links">
              {socialLinks.map((item, key) => (
                <li key={key}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.icon}
                    <span className="link-text">{item.text}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="form-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <p className="title">Precisa de ajuda?</p>
                <p>
                  Marque a sua consulta para que possamos analisar as melhores
                  soluções ao seu dispor
                </p>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 pt-5 pt-md-0">
              <div className="custom-form">
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  placeholder="Nome"
                  value={formData.nome}
                  onChange={o => handleChange(o, false)}
                />
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={o => handleChange(o, false)}
                />
                <input
                  type="text"
                  name="assunto"
                  id="assunto"
                  placeholder="Assunto"
                  value={formData.assunto}
                  onChange={o => handleChange(o, false)}
                />
                {/* <select
                  name="assunto"
                  id="assunto"
                  placeholder="Assunto"
                  value={formData.assunto}
                  onChange={o => handleChange(o, false)}
                >
                  <option>Opção 1</option>
                  <option>Opção 2</option>
                  <option>Opção 3</option>
                  <option>Opção 4</option>
                </select> */}
                <textarea
                  name="mensagem"
                  id="mensagem"
                  placeholder="Mensagem"
                  rows={5}
                  onChange={o => handleChange(o, false)}
                />
                <div className="custom-checkbox">
                  <div
                    className={
                      "checkbox-container " +
                      (formData.aceita_politica ? "checked" : "")
                    }
                    onClick={() => handleChange(null, true)}
                  >
                    <Check color="#f4e6e1" size={10} strokeWidth="3" />
                  </div>
                  <span>
                    Li e aceito a{" "}
                    <Link to="/politica-privacidade">
                      política de privacidade
                    </Link>
                  </span>
                </div>

                <div className="form-footer">
                  <span
                    className={"form-message " + (error ? "error" : "success")}
                  >
                    {showMessage && (
                      <>
                        {error ? (
                          <AlertCircle color="#d66f6f" size={16} />
                        ) : (
                          <Checks color="#6ac473" size={16} />
                        )}{" "}
                        {message}
                      </>
                    )}
                  </span>
                  <button className="main-link p-0" onClick={handleSubmit}>
                    Submeter —
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="blob1">
          <Blob size={250} color={"#fff"} blobShape={4} borderWidth={2} />
        </div>
        <div className="blob2">
          <Blob size={200} color={"#fff"} blobShape={1} borderWidth={2} />
        </div>
      </div>
      <div className="copyright-section">
        <div className="container">
          <span className="useful-links">
            {usefulLinks.map((item, key) => (
              <Link to={item.url} key={key}>
                {item.text}
              </Link>
            ))}
          </span>
          <span className="copyright">© 2022 MMV Advogados</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
