import { Link } from "gatsby"
import React from "react"
import Logo from "../images/logo.svg"

const MobileNavigation = ({ links }) => {
  return (
    <div className="mobile-navigation">
      <input
        type="checkbox"
        className="mobile-navigation__checkbox"
        id="navi-toggle"
      />

      <label htmlFor="navi-toggle" className="mobile-navigation__button">
        <span className="mobile-navigation__icon">&nbsp;</span>
      </label>

      <div className="mobile-navigation__background">&nbsp;</div>

      <Link to="/" className="mobile-navigation__menu-logo">
        <img src={Logo} alt={"MMV Advogados"} width={120} />
      </Link>

      <nav className="mobile-navigation__nav">
        <ul className="mobile-navigation__list">
          {links.map(link => {
            //const isDropdown = link.sublinks.length > 0

            return (
              <li className="mobile-navigation__item" key={link.url}>
                <Link
                  to={link.url && "/" + link.url}
                  className="mobile-navigation__link"
                >
                  {link.text}
                </Link>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

export default MobileNavigation
