import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import MobileNavigation from "./MobileNavigation"
import Logo from "../images/logo.svg"
import Blob from "./Blob"
import { colors } from "../assets/icons"

const pageLinks = [
  {
    text: "Sobre nós",
    url: "sobre-nos",
    description: "Sobre nós descrição",
    sublinks: [],
  },
  {
    text: "Competências",
    url: "",
    description: "Competências descrição",
    sublinks: [
      {
        text: "Serviços",
        url: "#servicos",
        description: "Serviços descrição",
        sublinks: [],
      },
      {
        text: "Áreas de Atuação",
        url: "#areas-atuacao",
        description: "Áreas de Atuação descrição",
        sublinks: [],
      },
    ],
  },
  {
    text: "Artigos",
    url: "artigos",
    description: "Artigos descrição",
    sublinks: [],
  },
  {
    text: "Contactos",
    url: "contactos",
    description: "Contactos descrição",
    sublinks: [],
  },
  {
    text: "Agendar Consulta",
    url: "#agendar-consulta",
    description: "Agendar Consulta descrição",
    sublinks: [],
  },
]

const Header = () => {
  const [scroll, setScroll] = React.useState(1)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  React.useEffect(() => {
    let isMounted = true
    window.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        if (isMounted) setScroll(scrollCheck)
      }
    })

    return () => {
      isMounted = false
    }
  }, [scroll])

  return (
    <header className={!scroll ? "header-scrolled" : ""}>
      <div className="container">
        <Link to="/" className="menu-logo">
          <img src={Logo} alt={data.site.siteMetadata.title} width={120} />
        </Link>
        <div className="menu-links">
          {pageLinks.map(link => {
            const isDropdown = link.sublinks.length > 0

            return (
              <div
                className={"menu-link " + (isDropdown ? "dropdown" : "")}
                key={link.url}
              >
                {link.url.includes("#") ? (
                  <a href={link.url}>{link.text}</a>
                ) : (
                  <Link
                    to={link.url && "/" + link.url}
                    className={isDropdown ? "dropdown-link" : ""}
                  >
                    {link.text}
                  </Link>
                )}
                {isDropdown && (
                  <div className="dropdown-content">
                    {link.sublinks.map(sublink =>
                      sublink.url.includes("#") ? (
                        <a
                          href={"/" + sublink.url}
                          className="dropdown-item"
                          key={sublink.url}
                        >
                          {sublink.text}
                        </a>
                      ) : (
                        <Link
                          to={sublink.url}
                          className={isDropdown ? "dropdown-link" : ""}
                          key={sublink.url}
                        >
                          {sublink.text}
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <div className="blob">
          <Blob
            size={200}
            color={colors.primary}
            blobShape={3}
            borderWidth={2}
          />
        </div>

        <MobileNavigation links={pageLinks} />
      </div>
    </header>
  )
}

export default Header
